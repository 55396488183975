@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-white;
	position: relative;
	color: $primary-color;
	font-family: $primary-font; 
	line-height: 1.5;
	width: 100%;
	overflow-x: hidden;
	background-image: url('../img/BG-Emilia.gif');
}

a {
	color: inherit;

	@include hover-focus {
		color: $primary-color;
	}
}

#header {
	width: 100%;
	overflow-x: hidden;
	.marquee{
		width: 100%;
		overflow-x: hidden;
		padding: rems(20) 0;
		background-image: url('../img/Fondo borde margen superior.gif');
		// white-space: nowrap;
	}
	.marquee-text{
		animation: marquee 20s infinite forwards linear;
		
		width: 100%;
		display: flex;
		align-items: center;
		img{
			flex-basis: 20%;
			width: 20%;
		} 
	}
	.marquee-text-2{
		width: 100%;
		animation: marquee 20s infinite forwards linear;
		animation-delay: 10s;
		position: absolute;
		transform: translateX(100%);
		top: rems(20);
		display: flex;
		align-items: center;
		img{
			flex-basis: 20%;
			width: 20%;
		}
	}
	@keyframes marquee {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(-100%)
		}
		
	}

	.wide-btn{
		display: block;
		padding: .5em 1em;
		margin: 0;
		width: 100%;
		border: 7px solid $primary-color;
		border-left: none;
		border-right: none;
		text-align: center;
		font-size: rems(28);
		text-transform: uppercase;
		font-weight: normal;
		text-decoration: none;
		background-image: linear-gradient(to bottom,$color-white, $color-pink);
		
		font-family: $secondary-font;
		color: #FAE1EE;
		line-height: 1.2;
		-webkit-text-stroke: 2px $primary-color;
		@include hover-focus{
			opacity: .7;
		}
	}
}

#main {
	padding: rems(80) 0;
	.container{
		display: flex;
		gap: rems(30);
		
		@media screen and (min-width: 901px){
			.mobile{
				display: none;
			}
		}
		@media screen and (max-width: 900px){
			flex-direction: column-reverse;
			.desktop{
				display: none;
			}
		}
		.column{
			flex: 3;
			flex-direction: column;
			display: flex;
			gap: rems(30);
		}
		.column-sm{
			flex: 2;
		}
	}

	h2{
		text-decoration: underline;
		margin: 0 0 .5em;
		font-weight: normal;
	}

	h1{
		margin: 0;
		img{
			width: 100%;
			object-fit: cover;
			aspect-ratio: 6/3;
		}
	}
	.box{
		display: block;
		padding: rems(30);
		border: 4px solid $primary-color;
		background-image: linear-gradient(to bottom, $color-white, $color-pink);
		.stars{
			color: $color-white;
		}
		&.tight{
			@media screen and (max-width: 560px){
				padding: rems(20) rems(10);
			}
		}
		&.tour-box{
			width: 100%;
			.centered2{
				width: 100%;
				max-width: rems(500)
			}

			#events{
				width: 100%;
				max-width: rems(930);
				margin: 0 auto;
				.event{
					padding: rems(25) 0;
					display: flex;
					font-size: rems(20);
					text-decoration: underline;
					gap: rems(10);
					align-items: center;
					color: #ED027D;
					>div{
						flex: 1;
						&:first-child{
							flex: .5;
						}
					}
					.event-venue{
						span{
							display: block;
							font-size: .8em;
						}
					}
					&+.event{
						border-top: 5px solid #00D4D2;
					}
					@media screen and (max-width: 850px){
						flex-direction: column;
						text-align: center;
						gap: rems(5);
					}
				}
				.btn{
					display: block;
					text-transform: uppercase;
					margin: 0;
					border: 7px solid $primary-color;
					padding: rems(8);
					text-align: center;
					font-size: rems(24);
					color: $color-white;
					font-weight: normal;
					text-decoration: none;
					background-image: linear-gradient(to top,$primary-color, #EA7AA4);
					@include hover-focus{
						opacity: .7;
					}
					
					@media screen and (max-width: 560px){
						font-size: rems(26);
					}
				}
			}
		}
		.btn{
			display: block;
			margin: rems(30) auto;
			border: 7px solid $primary-color;
			padding: rems(10);
			text-align: center;
			font-size: rems(35);
			color: $color-white;
			font-weight: normal;
			text-decoration: none;
			background-image: linear-gradient(to top,$primary-color, $color-pink);
			
			@media screen and (max-width: 560px){
				font-size: rems(26);
			}
		}
		.btn+img{
			width: rems(120);
		}
		.wide{
			display: block;
			width: 100%;
		}
		.quote{
			display: block;
			width: rems(230);
			margin: rems(20) auto;
			background-image: url('../img/quote.png');
			background-size: 100% 100%;
			color: $color-white;
			font-weight: normal;
			padding: rems(30) rems(30) rems(10);
			font-size: rems(25);
			text-align: center;

		}
		.square-img{
			aspect-ratio: 1/1;
			width: 60%;
			margin: 0 auto;
			object-fit: cover;
			object-position: center;
		}
		video{
			width: 100%;
			height: auto;
		}

		.centered{
			display: block;
			width: max-content;
			max-width: 100%;
			margin: -5% auto 0;;
		}
		.centered2{
			display: block;
			width: max-content;
			max-width: 100%;
			width: rems(270);
			margin: rems(30) auto;
		}
		.countdown{
			display: flex;
			justify-content: center;
			font-size: rems(60);
			font-family: $secondary-font;
			color: #FAE1EE;
			line-height: 1.2;
			-webkit-text-stroke: 3px $primary-color;
			@media screen and (max-width: 1080px){
				font-size: rems(40);
				-webkit-text-stroke: 2px $primary-color;
			}
			@media screen and (max-width: 600px){
				font-size: rems(24);
			}
			// text-shadow: 4px 4px 0 $primary-color, -4px 4px 0 $primary-color, 4px -4px 0 $primary-color, -4px -4px 0 $primary-color, 0px 4px 0 $primary-color, 4px 0px 0 $primary-color, 0px -4px 0 $primary-color, -4px 0px 0 $primary-color;
			span{
				width: 1.3em;
				text-align: center;
			}
		}
	}

	.wrapper{
		display: flex;
		text-decoration: none;
		>img{
			object-fit: cover;
			width: 20%;
		}
		.font{
			text-align: center;
			font-size: rems(28);
			text-transform: uppercase;
			font-weight: normal;
			text-decoration: none;
			font-family: $secondary-font;
			color: #fff;
			line-height: 1.2;
			-webkit-text-stroke: 2px $primary-color;
		}
		.graphic{
			padding: rems(20) 0 rems(10) rems(20); 
			display: flex;
			position: relative;
			align-items: flex-end;
			justify-content: space-between;
			width: 100%;
			>div{
				img{
					height: rems(180);
					width: auto;
				}
				&:nth-child(2){
					transform: scaleX(-1);
				}
			}
			div{
				&:nth-child(3){
					img{
						width: 100%;
						height: auto;
						display: block;
					}
					position: absolute;
					width: 70%;
					top: rems(30);
					left: 15%;
				}
			}
			img{
				
				&:nth-child(4){
					position: absolute;
					left: 50%;
					bottom: rems(30);
					transform: translateX(-50%);
				}

			}
		}
		
		@media screen and (max-width: 560px){
			flex-direction: column-reverse;
			align-items: center;
			>img{
				width: 100%;
				aspect-ratio: 4/3;
			}
			.graphic{
				width: 100%;
			}
		}
	}

	.player{
		padding: rems(120) 0;
		transform: rotate(-30deg);
	}

}

#footer {
	.container{
		width: 100%;
		max-width: rems(800);
	}
	a{
		display: inline-block;
	}
	color: $color-black;
	text-align: center;
	font-size: rems(10);
	text-transform: uppercase;
}