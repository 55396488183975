$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$primary-color: #9836C6;
$color-pink:#BF5DE6;

@font-face {
    font-family: 'Cytone';
    src: url('../font/CytoneRegular.woff2') format('woff2'),
        url('../font/CytoneRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Windows Command Prompt';
    src: url('../font/Windows-Command-Prompt.woff2') format('woff2'),
        url('../font/Windows-Command-Prompt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



$primary-font: 'Windows Command Prompt', sans-serif;
$secondary-font: 'Cytone', sans-serif;